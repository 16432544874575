import React, { useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'

const SwiperCard = ({ participants }) => {
    const videoRefs = useRef({});

    useEffect(() => {
        participants.forEach((participant) => {
            if (participant.status === 'connected' && participant.mediaStream) {
                const videoElement = videoRefs.current[participant.peer];
                if (videoElement && videoElement.srcObject !== participant.mediaStream) {
                    videoElement.srcObject = participant.mediaStream;
                    videoElement.muted = false;
                    videoElement.play().catch(error => console.error('Error playing video:', error));
                }
            }
        });
    }, [participants]);

    return (
        <div>
            <div id="parentView"></div>
            <Swiper
                spaceBetween={6}
                slidesPerView={2}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
                navigation={true}
                style={{ display: participants.length > 0 ? 'block' : 'block' }}
            >
                {participants.map((participant) => (
                    <SwiperSlide key={participant.peer} className="bg-dark">
                        {participant.status === 'connecting' ? (
                            <div className="py-3">
                                <h5 className="text-light-gray font-weight-500 mt-2 mb-1" style={{fontSize: 10}}>Binge Watcher</h5>
                                <p style={{fontSize: 8}} className="text-light">Connecting...</p>
                            </div>
                        ) : (
                            <video 
                                className="rounded-0 video-mirror img-fluid" 
                                ref={el => videoRefs.current[participant.peer] = el}
                                playsInline
                                autoPlay
                            ></video>
                        )}
                    </SwiperSlide>
                ))}

                <SwiperSlide className="bg-dark">
                    <div className="px-4 py-3">
                        <div className="d-flex justify-content-center">
                            <img src={require("assets/img/users/shivam.jpeg")} className="img-fluid" alt="bingeAsk Logo" style={{height:30, width:30, objectFit: "cover", borderRadius: 50}} />
                        </div>
                        <h5 className="mt-2 text-light-gray font-weight-500 mb-0" style={{fontSize: 10}}>Shivam</h5>
                    </div>
                </SwiperSlide>

                <SwiperSlide className="bg-dark">
                    <div className="py-3">
                        <h5 className="text-light-gray font-weight-500 mt-2 mb-1" style={{fontSize: 10}}>Binge Watcher</h5>
                        <p style={{fontSize: 8}} className="text-light">Connecting...</p>
                    </div>
                </SwiperSlide>

                <SwiperSlide className="bg-dark">
                    <div>
                        <h5 className="mt-3 text-light-gray fs-12 font-weight-500 mb-0">User Joining</h5>
                        <small style={{fontSize: 8}} className="text-light">(Connecting to audio & video)</small>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default SwiperCard