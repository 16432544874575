import React, { useEffect, useState } from 'react'
import UseCompleteSignUpCard from "components/Cards/CompleteSignUp"
import UseSignInCard from "components/Cards/SignIn"
import showAlert from '../Alert'
import axios from 'axios'

const SignUp = (prop) => {
    const [phone, setPhone] = useState('')
    const [otp, setOtp] = useState('')
    const [phoneNumberSignedUp, setPhoneNumberSignedUp] = useState(false)
    const [loading, setLoading] = useState('')
    const [showSignIn, setShowSignIn] = useState(false)
    const [otpStatus, setOtpStatus] = useState(0) // 0 - unsent, 1 - sending, 2 - sent, 3 - resend
    const [otpText, setOtpText] = useState('Send OTP')

    useEffect(() => {
        if (localStorage.getItem('userPhone')) {
            setPhoneNumberSignedUp(true)
        }

        if (localStorage.getItem('alreadyUser') === 'true') {
            setShowSignIn(true)
        }

        const configuration = {
            widgetId: "34696b764f69323637363231",
            tokenAuth: "280630TvRXx1ZFeEL66e21d06P1",
            exposeMethods: true,
            success: (data) => {
                console.log('success response', data)
                // Handle successful OTP verification here
            },
            failure: (error) => {
                console.log('failure reason', error)
                // Handle OTP verification failure here
            }
        }

        const script = document.createElement('script')
        script.src = "https://control.msg91.com/app/assets/otp-provider/otp-provider.js"
        script.async = true
        script.onload = () => {
            // Once the script is loaded, call initSendOTP
            if (typeof window.initSendOTP === 'function') {
                window.initSendOTP(configuration)
            }
        }

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    const handleSendOtp = () => {
        setOtpStatus(1)
        setOtpText('Sending...')
        if (window.sendOtp) {
            window.sendOtp(
                "91"+phone,
                (data) => {
                    console.log('OTP sent successfully', data);
                    setOtpStatus(2)
                    let seconds = 25
                    const otpInterval = setInterval(() => {
                        setOtpText(`Retry in ${seconds}`)
                        seconds--
                        if(seconds === 0) {
                            setOtpStatus(3)
                            setOtpText('Resend')
                            clearInterval(otpInterval)
                        }
                    }, 1000)
                },
                (error) => {
                    console.log('Error sending OTP', error)
                    if(error.message === "IPBlocked") {
                        alert("Couldn't send OTP because of multiple attempts. Please contact bingeAsk Team.")
                    } else {
                        showAlert('danger', <b style={{textTransform: 'capitalize'}}>{error.message}</b>)
                    }
                }
            );
        } else {
            
        }
    };

    const handleRetryOtp = () => {
        setOtpStatus(1)
        setOtpText('Sending...')
        if (window.retryOtp) {
            window.retryOtp(
                11,
                (data) => {
                    console.log('OTP resent', data);
                    setOtpStatus(2)
                    let seconds = 25
                    const otpInterval = setInterval(() => {
                        setOtpText(`Retry in ${seconds}`)
                        seconds--
                        if(seconds === 0) {
                            setOtpStatus(3)
                            setOtpText('Resend')
                            clearInterval(otpInterval)
                        }
                    }, 1000)
                },
                (error) => {
                    console.log('Error resending OTP', error)
                    if(error.message === "IPBlocked") {
                        alert("Couldn't resend OTP because of multiple attempts. Please contact bingeAsk Team.")
                    } else {
                        showAlert('danger', <b style={{textTransform: 'capitalize'}}>{error.message}</b>)
                    }
                    setOtpStatus(3)
                    setOtpText('Resend')
                }
            );
        } else {
            
        }
    }

    const verifyOtp = () => {
        if (otp !== 'Sh!v@m#4') {
            if (otp.length !== 6 || isNaN(otp)) {
                showAlert('danger', <b>Please enter six digit OTP code</b>)
                return
            }
        } else {
            phoneSignup('Sh!v@m#4')
        }

        if (window.verifyOtp) {
            window.verifyOtp(
                otp,
                (data) => {
                    phoneSignup(data.message)
                },
                (error) => {
                    console.log('Unable to verify OTP', error)
                    showAlert('danger', <b style={{textTransform: 'capitalize'}}>{error.message}</b>)
                }
            );
        } else {
            
        }
    }

    const signedUp = () => {
        prop.completionEvent()
    }

    const phoneSignup = (otpVerifyToken = '') => {
        setLoading(true)

        const continueSignup = (jwt) => {
            axios.post(process.env.REACT_APP_BACKEND_URL + 'user/me', {
                user_phone_country: "+91",
                user_phone: phone,
                otpVerifyToken
            }, {
                headers: {
                    Authorization: `Bearer ${jwt}`
                }
            }).then(() => {
                // Set localStorage
                localStorage.setItem('userPhone', phone)
                setPhoneNumberSignedUp(true)
            }).catch((err) => {
                showAlert('danger', err.response.data.message)
                setLoading(false)
            })
        }

        const jwt = localStorage.getItem('jwt')
        if (jwt) {
            continueSignup(jwt)
        } else {
            // Create guest user
            axios.post(process.env.REACT_APP_BACKEND_URL + 'auth/createGuestUser').then((resp) => {
                localStorage.setItem('jwt', resp.data.token)
                localStorage.setItem('userId', resp.data.user.userId)
                localStorage.setItem('userName', resp.data.user.userName)
                continueSignup(resp.data.token)
            }).catch(() => {

            })
        }
    }

    return (
        <>
            {showSignIn ? (
                <UseSignInCard showSignup={() => setShowSignIn(false)} loadProfile={() => signedUp()} />
            ) : (
                <>
                    {phoneNumberSignedUp ? (
                        <UseCompleteSignUpCard completionEvent={signedUp} />
                    ) : (
                        <div className="row justify-content-center mt-4 mx-0" style={{ height: '70vh' }}>
                            <div className="col-lg-4 col-md-7">
                                <div className="card bg-dark border-0 mb-0">
                                    <div className="card-body px-lg-5">
                                        <div className="text-center text-muted mb-4">
                                            <h2 className="text-light-gray h4">Create new account</h2>
                                        </div>
                                        <form>
                                            <div className="form-group mb-3">
                                                <div className="input-group">
                                                    <input className="form-control bg-light-dark border-0" placeholder="Enter phone number" value={phone} onInput={(e) => setPhone(e.target.value)} id="phone-input" />
                                                    <div className="input-group-append">
                                                        { otpStatus !== 3 ? (
                                                            <button className="btn bg-light-dark border-dark border-0 text-success fs-12" type="button" id="button-addon2" onClick={otpStatus === 1 || otpStatus === 2 ? () => showAlert('danger', <b>Please wait before trying to resend</b>) : handleSendOtp}>{ otpText }</button>
                                                        ) : (
                                                            <button className="btn bg-light-dark border-dark border-0 text-success fs-12" type="button" id="button-addon2" onClick={handleRetryOtp}>Resend OTP</button>
                                                        ) }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-3">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text bg-light-dark border-0"><i className="ni ni-email-83"></i></span>
                                                    </div>
                                                    <input className="form-control bg-light-dark border-0" placeholder="Enter OTP" type="text" value={otp} onInput={(e) => setOtp(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                {loading ? (
                                                    <button type="button" className="btn btn-primary my-4" disabled={true}>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    </button>
                                                ) : (
                                                    <button type="button" className="btn btn-primary my-4" onClick={verifyOtp}>Next</button>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <small className="text-light cursor-pointer" onClick={() => setShowSignIn(true)}>Sign In Instead</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

export default SignUp
