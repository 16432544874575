import { useState } from "react"
import showAlert from '../Alert'

const SeatingArrangmentCard = (props) => {

    const [ editMode, setEditMode ] = useState(false)

    const { seats } = props

    const copyPartyLink = async () => {
        await navigator.clipboard.writeText(process.env.REACT_APP_FRONTEND_URL + 'join-party/' + props.ticketToken)
        showAlert('primary', 'Link copied to the clipboard!')
    }

    const updateSeat = (seatPosition) => {
        setEditMode(false)
        props.updateMySeat(seatPosition)
    }

    return (
        <>
            <div className="justify-content-center" style={{ display: 'grid' }}>
                <div style={{ width: '300px' }}>
                    {/* First Row with 4 seats */}
                    <div className="row justify-content-center">
                        {[...Array(4)].map((_, index) => {
                            const user = seats.find((seat) => seat.seat_position === index + 1);
                            return (
                                <span key={index} className="theatre-seat">
                                    {user ? (
                                        <img
                                            src={process.env.REACT_APP_PFP_URL + user.user_pfp}
                                            className="img-fluid seat-img"
                                            alt={`Seat ${user.seat_position}`}
                                            style={{borderRadius: 6}}
                                        />
                                    ) : (
                                        editMode ? <span className="text-white fs-seat-number" onClick={() => updateSeat(index + 1)}>{index + 1}</span> : null
                                    )}
                                </span>
                            );
                        })}
                    </div>

                    {/* Second Row with 6 seats */}
                    <div className="row justify-content-center">
                        {[...Array(6)].map((_, index) => {
                            const user = seats.find((seat) => seat.seat_position === index + 5);
                            return (
                                <span key={index} className="theatre-seat">
                                    {user ? (
                                        <img
                                            src={process.env.REACT_APP_PFP_URL + user.user_pfp}
                                            className="img-fluid seat-img"
                                            alt={`Seat ${user.seat_position}`}
                                            style={{borderRadius: 6}}
                                        />
                                    ) : (
                                        editMode ? <span className="text-white fs-seat-number" onClick={() => updateSeat(index + 5)}>{index + 5}</span> : null
                                    )}
                                </span>
                            );
                        })}
                    </div>

                    {/* Next Four Rows with 9 seats each */}
                    {[10, 19, 28, 37].map((start, rowIndex) => (
                        <div key={rowIndex} className="row justify-content-center">
                            {[...Array(9)].map((_, index) => {
                                const seatNum = start + index + 1;
                                const user = seats.find((seat) => seat.seat_position === seatNum);
                                return (
                                    <span key={index} className="theatre-seat">
                                        {user ? (
                                            <img
                                                src={process.env.REACT_APP_PFP_URL + user.user_pfp}
                                                className="img-fluid seat-img"
                                                alt={`Seat ${user.seat_position}`}
                                                style={{borderRadius: 6}}
                                            />
                                        ) : (
                                            editMode ? <span className="text-white fs-seat-number" onClick={() => updateSeat(seatNum)}>{seatNum}</span> : null
                                        )}
                                    </span>
                                );
                            })}
                        </div>
                    ))}

                    {/* Seventh Row with 6 seats */}
                    <div className="row justify-content-center">
                        {[...Array(6)].map((_, index) => {
                            const user = seats.find((seat) => seat.seat_position === index + 47);
                            return (
                                <span key={index} className="theatre-seat">
                                    {user ? (
                                        <img
                                            src={process.env.REACT_APP_PFP_URL + user.user_pfp}
                                            className="img-fluid seat-img"
                                            alt={`Seat ${user.seat_position}`}
                                            style={{borderRadius: 6}}
                                        />
                                    ) : (
                                        editMode ? <span className="text-white fs-seat-number" onClick={() => updateSeat(index + 47)}>{index + 47}</span> : null
                                    )}
                                </span>
                            );
                        })}
                    </div>

                    {/* Eighth Row with 4 seats */}
                    <div className="row justify-content-center">
                        {[...Array(4)].map((_, index) => {
                            const user = seats.find((seat) => seat.seat_position === index + 53);
                            return (
                                <span key={index} className="theatre-seat">
                                    {user ? (
                                        <img
                                            src={process.env.REACT_APP_PFP_URL + user.user_pfp}
                                            className="img-fluid seat-img"
                                            alt={`Seat ${user.seat_position}`}
                                            style={{borderRadius: 6}}
                                        />
                                    ) : (
                                        editMode ? <span className="text-white fs-seat-number" onClick={() => updateSeat(index + 53)}>{index + 53}</span> : null
                                    )}
                                </span>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className="text-center mt-2">
                <span className="audio-video-icons" id="audio-video-icons-inside"></span>
                <i className="fa-solid fa-couch text-light-gray pl-2 pr-2 cursor-pointer" onClick={() => setEditMode(!editMode)}></i>
                <i className="fa-solid fa-link text-light-gray pl-2 pr-2 cursor-pointer" onClick={copyPartyLink}></i>
                <i className="fa-solid fa-person-walking-dashed-line-arrow-right text-light-gray pl-2 cursor-pointer" onClick={copyPartyLink}></i>
            </div>
        </>
    )
}

export default SeatingArrangmentCard
