import Home from "views/Home"
import Blog from "views/Blog"
import BlogReading from "views/BlogReading"
import TermsAndConditions from "views/TermsAndConditions"
import PrivacyPolicy from "views/PrivacyPolicy"
import TrendingShows from "views/TrendingShows"
import ShowDetails from "views/ShowDetails"
import PageNotFound from "views/PageNotFound"
import Netflix from "views/Netflix"
import OurStory from "views/OurStory"
import JoinParty from "views/JoinParty"
import FindFriends from "views/FindFriends"
import Messages from "views/Messages"
import Profile from "views/Profile"
import Theatre from "views/Theatre"
import Support from "views/Support"
import BookSeat from "views/BookSeat"

var routes = [
	{
		path: "/",
		name: "Home",
		icon: "ni ni-circle-08 text-pink",
		component: <Home />,
		layout: "/main",
	},
	{
		path: "/blog",
		name: "Blog",
		icon: "ni ni-circle-08 text-pink",
		component: <Blog />,
		layout: "/main",
	},
	{
		path: "/blog-reading",
		name: "Blog",
		icon: "ni ni-circle-08 text-pink",
		component: <BlogReading />,
		layout: "/main",
	},
	{
		path: "/terms-and-conditions",
		name: "Terms and Conditions",
		icon: "ni ni-circle-08 text-pink",
		component: <TermsAndConditions />,
		layout: "/main",
	},
	{
		path: "/privacy-policy",
		name: "Privacy Policy",
		icon: "ni ni-circle-08 text-pink",
		component: <PrivacyPolicy />,
		layout: "/main",
	},
	{
		path: "/trending-shows",
		name: "Trending Shows",
		icon: "ni ni-circle-08 text-pink",
		component: <TrendingShows />,
		layout: "/main",
	},
	{
		path: "/show-details",
		name: "Show Details",
		icon: "ni ni-circle-08 text-pink",
		component: <ShowDetails />,
		layout: "/main",
	},
	{
		path: "/netflix",
		name: "Netflix",
		icon: "ni ni-circle-08 text-pink",
		component: <Netflix />,
		layout: "/main",
	},
	{
		path: "/our-story",
		name: "Our Story",
		icon: "ni ni-circle-08 text-pink",
		component: <OurStory />,
		layout: "/main",
	},
	{
		path: "/join-party/:token",
		name: "Join Party",
		icon: "ni ni-circle-08 text-pink",
		component: <JoinParty />,
		layout: "/main",
	},
	{
		path: "/page-not-found",
		name: "Page Not Found",
		icon: "ni ni-circle-08 text-pink",
		component: <PageNotFound />,
		layout: "/main",
	},
	{
		path: "/find-friends",
		name: "Find Friends",
		icon: "ni ni-circle-08 text-pink",
		component: <FindFriends />,
		layout: "/main",
	},
	{
		path: "/messages",
		name: "Messages",
		icon: "ni ni-circle-08 text-pink",
		component: <Messages />,
		layout: "/main",
	},
	{
		path: "/profile",
		name: "Profile",
		icon: "ni ni-circle-08 text-pink",
		component: <Profile />,
		layout: "/main",
	},
	{
		path: "/user/:id",
		name: "User",
		icon: "ni ni-circle-08 text-pink",
		component: <Profile />,
		layout: "/main",
	},
	{
		path: "/theatre/:id",
		name: "Theatre",
		icon: "ni ni-circle-08 text-pink",
		component: <Theatre />,
		layout: "/main",
	},
	{
		path: "/support",
		name: "Support",
		icon: "ni ni-circle-08 text-pink",
		component: <Support />,
		layout: "/main",
	},
	{
		path: "/book-seat",
		name: "Book Seat",
		icon: "ni ni-circle-08 text-pink",
		component: <BookSeat />,
		layout: "/main",
	}

]
export default routes