import React, { useEffect, useState, useRef } from 'react'
import { Helmet } from 'react-helmet'
import UseSeatingArrangement from "components/Cards/SeatingArrangement"
import UseSwiper from "components/Cards/Swiper"
import classnames from "classnames"
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button,
    Card,
    CardBody,
    UncontrolledCollapse,
    FormGroup,
    Input
} from "reactstrap"
import { useParams } from "react-router-dom"
import axios from 'axios'
import socketIOClient from 'socket.io-client'
import { Peer } from "peerjs"
import showAlert from '../components/Alert'

const Theatre = () => {
    // State and ref declarations
    const [navPills, setNavPills] = useState(1)
    const videoRef = useRef(null)
    const frequencyDataRef = useRef(null)
    const analyserNodeRef = useRef(null)
    const sourceNodeRef = useRef(null)
    const audioContextRef = useRef(null)
    const soundInitializedRef = useRef(false)
    const userMediaInitializedRef = useRef(false)
    const [mediaStream, setMediaStream] = useState(null)
    const [mic, setMic] = useState(true)
    const [camera, setCamera] = useState(true)
    const [audioFrequencies, setAudioFrequencies] = useState({ 1: '0rem', 2: '0rem', 3: '0rem' })
    const [modalOpen, setModalOpen] = useState(true)
    const [micDevices, setMicDevices] = useState([])
    const [cameraDevices, setCameraDevices] = useState([])
    const [micDevice, setMicDevice] = useState(null)
    const [cameraDevice, setCameraDevice] = useState(null)
    const [ticketDetails, setTicketDetails] = useState({
        user_id: 0,
        user_name: '',
        ticket_token: ''
    })
    const [joinBtnEnabled, setJoinBtnEnabled] = useState(false)

    const [socket, setSocket] = useState(null)
    const [peer, setPeer] = useState(null)
    const [peerId, setPeerId] = useState(null)
    const [participants, setParticipants] = useState([])
    const [seats, setSeats] = useState([])
    const [myPrefSeat, setMyPrefSeat] = useState(1)

    const { id } = useParams()

    const setupPeer = (parameterSocket = null, autostart = false) => {
        if(parameterSocket === null) {
            parameterSocket = socket
        }

        const newPeer = new Peer(peerId, {
            config: {
                'iceServers': [
                    { url: 'stun:stun.l.google.com:19302' },
                    { url: 'turn:turn.flowres.io?transport=tcp', username: 'kp', credential: 'password' }
                ]
            }
        })

        newPeer.on('open', (peerId) => {
            // Join party with ticket and peer id
            parameterSocket.emit("joinSocketParty", id, peerId)
            setPeerId(peerId)
            handlePeerEvents(newPeer)
            setPeer(newPeer)
            if(autostart) {
                setTimeout(() => {
                    startWatchParty(parameterSocket, newPeer, peerId)
                }, 10000)
            }
        })
    }

    const startSocket = (autostart = false) => {
        // Initialize socket
        const newSocket = socketIOClient(process.env.REACT_APP_WS_URL, {
            autoConnect: false
        })

        newSocket.auth = { token: localStorage.getItem('jwt'), window: 'chat' }

        // Connect when this user is added to the ticket
        newSocket.connect()

        // Get Peer ID
        newSocket.on('connect', () => {
            // Set socket
            setupPeer(newSocket, autostart)
            setSocket(newSocket)
        })

        newSocket.on('partyJoined', () => {
            setJoinBtnEnabled(true)
        })
    }

    useEffect(() => {
        // Get ticket details
        axios.get(process.env.REACT_APP_BACKEND_URL + `ticket/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        }).then((resp) => {
            const ticket = resp.data.ticket
            // if(ticket.ticket_status == '1') {
            //     alert('Ticket closed, please create a new one.')
            //     window.close()
            // }
            setTicketDetails({
                user_id: ticket.user_id,
                user_name: ticket.user_name.split(" ")[0],
                ticket_token: ticket.ticket_token
            })
            startSocket()
        }).catch((err) => {
            console.log(err)
            showAlert('danger', 'Unauthorized')
            // window.close()
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Constants
    const detectThreshold = 10

    const updateAudioFrequencies = (val1, val2, val3) => {
        setAudioFrequencies({
            1: `${val1}rem`,
            2: `${val2}rem`,
            3: `${val3}rem`,
        })
    }

    // Function to check for sound
    const checkForSound = () => {
        analyserNodeRef.current.getByteFrequencyData(frequencyDataRef.current)

        const sum = frequencyDataRef.current.reduce((acc, value) => acc + value, 0)
        const average = sum / frequencyDataRef.current.length

        // Check if the average frequency is above the threshold
        const isSound = average > detectThreshold

        // Update audio frequencies
        if (isSound) {
            if (average > 20) {
                updateAudioFrequencies(0.8, 1.3, 0.8)
            } else if (average > 15) {
                updateAudioFrequencies(0.6, 1.0, 0.6)
            } else if (average > 9) {
                updateAudioFrequencies(0.4, 0.8, 0.4)
            }
        } else {
            updateAudioFrequencies(0, 0, 0)
        }

        setTimeout(checkForSound, 50)
    }

    // Effect to initialize audio context
    useEffect(() => {
        if (!mediaStream || soundInitializedRef.current) {
            return
        }

        soundInitializedRef.current = true
        audioContextRef.current = new AudioContext()
        sourceNodeRef.current = audioContextRef.current.createMediaStreamSource(mediaStream)
        analyserNodeRef.current = audioContextRef.current.createAnalyser()
        analyserNodeRef.current.fftSize = 2048
        analyserNodeRef.current.smoothingTimeConstant = 0.8

        sourceNodeRef.current.connect(analyserNodeRef.current)

        frequencyDataRef.current = new Uint8Array(analyserNodeRef.current.frequencyBinCount)

        checkForSound()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaStream])

    useEffect(() => {
        // When video element is initialized
        if (videoRef.current && !userMediaInitializedRef.current) {
            userMediaInitializedRef.current = true
            navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then(stream => {
                setMediaStream(stream)
                videoRef.current.srcObject = stream
                videoRef.current.muted = true
                videoRef.current.play()

                // Enumerate devices
                navigator.mediaDevices.enumerateDevices().then((devices) => {
                    const microphones = devices.filter((device) => device.kind === 'audioinput')
                    const cameras = devices.filter((device) => device.kind === 'videoinput')

                    setMicDevices(microphones)
                    setCameraDevices(cameras)
                })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoRef])

    // Effect to handle camera and mic settings
    useEffect(() => {
        if (!mediaStream) {
            return
        }

        mediaStream.getAudioTracks().forEach((track) => {
            track.enabled = mic
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mic])

    useEffect(() => {
        if (!mediaStream) {
            return
        }

        const updateTrackEnabled = (track, enable) => {
            track.forEach((track) => {
                if (enable) {
                    // Update mediaStream and replace track
                    navigator.mediaDevices.getUserMedia({ video: true }).then(newStream => {
                        // Removes track so camera can stop completely
                        mediaStream.getVideoTracks().forEach((track) => {
                            mediaStream.removeTrack(track)
                        })
                        // Add video to mediastream to play for myself
                        newStream.getVideoTracks().forEach((track) => {
                            mediaStream.addTrack(track)
                        })

                        // videoRef.current.srcObject = newStream
                        // videoRef.current.muted = true
                        // videoRef.current.play()

                        Object.values(peer.connections).forEach((connections) => {
                            connections.forEach((conn) => {
                                if (conn.peerConnection) {
                                    const sender = conn.peerConnection.getSenders().find(s => s.track?.kind === 'video');
                                    if (sender) {
                                        sender.replaceTrack(newStream.getVideoTracks()[0]);
                                    }
                                }
                            });
                        });
                    })
                } else {
                    track.stop()
                }
            })
        }

        if (camera) {
            updateTrackEnabled(mediaStream.getVideoTracks(), true)
        } else {
            updateTrackEnabled(mediaStream.getVideoTracks(), false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [camera])

    useEffect(() => {
        if (mediaStream) {
            const stopTrack = (tracks) => {
                tracks.forEach((track) => {
                    track.stop()
                })
            }

            stopTrack(mediaStream.getVideoTracks())

            stopTrack(mediaStream.getAudioTracks())

            let finalMicDevice = micDevice
            let finalCameraDevice = cameraDevice
            if (!micDevice) {
                finalMicDevice = mediaStream.getAudioTracks()[0].getSettings().deviceId
            }
            if (!cameraDevice) {
                finalCameraDevice = mediaStream.getVideoTracks()[0].getSettings().deviceId
            }
            // If micDevice changes, reinitialize media stream with the new micDevice
            navigator.mediaDevices.getUserMedia({ audio: { deviceId: finalMicDevice }, video: { deviceId: finalCameraDevice } }).then((stream) => {
                setMediaStream(stream)
                videoRef.current.srcObject = stream
                videoRef.current.muted = true
                videoRef.current.play()
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [micDevice, cameraDevice])

    useEffect(() => {
        participants.forEach((participant) => {
            if (participant.status === 'connecting') {
                // Connect all connecting participants
                peer.connect(participant.peer).on('open', () => {
                    peer.call(participant.peer, mediaStream)
                })
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participants])

    const reconnectCall = () => {
        setParticipants([])
        setSeats([])
        try {
            peer.destroy()
        } catch(err) {
            console.log(err)
        }
        try {
            socket.disconnect()
        } catch(err) {
            console.log(err)
        }
        startSocket(true)
    }

    const updateMySeat = (seatPosition, socket) => {
        setMyPrefSeat(seatPosition)
        socket.emit("mySeat", seatPosition)
        setSeats(prevSeats => {
            return prevSeats.map(prevSeat => {
                if (prevSeat.user_id == localStorage.getItem('userId')) { // eslint-disable-line eqeqeq
                    return {
                        ...prevSeat,
                        seat_position: seatPosition
                    }
                }
                // Otherwise, return the seat as it is
                return prevSeat
            })
        })
    }

    const startReconnecting = async () => {
        return
        let isInternetRestored = false

        try {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL + 'echo', { cache: 'no-store' })
            if (response.ok) {
                // console.log("internet restored")
                isInternetRestored = true
            } else {
                // console.log('Server responded, but not OK.')
            }
        } catch (error) {
            // If fetch fails, the internet is still down
            // console.log('No internet connection yet...', error)
        }

        if (isInternetRestored) {
            reconnectCall()
        } else {
            setTimeout(() => {
                startReconnecting()
            }, 3000)
        }
    }

    const handlePeerEvents = (peer) => {
        // When getting a connection request
        peer.on('connection', (connection) => {
            setParticipants(prevParticipants => {
                // Is connection already made?
                const isPeerInParticipants = prevParticipants.some(participant => participant.peer === connection.peer)
                if (!isPeerInParticipants) {
                    return [...prevParticipants, { peer: connection.peer, status: 'connecting' }]
                } else {
                    return prevParticipants
                }
            })
        })

        // When getting a call
        peer.on('call', (call) => {
            setParticipants(prevParticipants => {
                const isPeerInParticipants = prevParticipants.some(participant => participant.peer === call.peer && participant.status === 'connected')

                if (!isPeerInParticipants) {
                    call.answer()
                    let streamed = false
                    call.on('stream', (stream) => {
                        if (streamed) {
                            return
                        }
                        streamed = true

                        setParticipants(prevParticipants => {
                            // Remove connecting
                            const updatedParticipants = prevParticipants.filter(participant => participant.peer !== call.peer) // eslint-disable-line eqeqeq

                            // Add this stream to call list
                            return [...updatedParticipants, { peer: call.peer, mediaStream: stream, status: 'connected' }]
                        })
                    })
                }
                return prevParticipants
            })
        })

        peer.on('disconnected', (e) => {
            startReconnecting()
        })

        peer.on('error', (err) => {
            console.log(err)
            // alert('There was a problem connecting to the server:', err)
            // window.location.reload()
        })
    }

    const startWatchParty = (socket, peer, peerId) => {
        videoRef.current.remove()
        const aiBg = document.getElementById('ai-bg')
        const audioVideoIcons = document.getElementById('audio-video-icons')
        aiBg.remove()
        audioVideoIcons.remove()
        document.getElementById('parentView').appendChild(videoRef.current)
        document.getElementById('ai-bg-inside').appendChild(aiBg)
        document.getElementById('audio-video-icons-inside').appendChild(audioVideoIcons)
        setModalOpen(false)

        socket.on('seatUpdate', (userId, seatPosition) => {
            setSeats(prevSeats => {
                // Check if the user is already in the seats array
                const userIndex = prevSeats.findIndex(prevSeat => prevSeat.user_id === userId)

                if (userIndex !== -1) {
                    // If the user is found, update its seatPosition
                    return prevSeats.map((prevSeat, index) => {
                        if (index === userIndex) {
                            return {
                                ...prevSeat,
                                seat_position: seatPosition
                            }
                        }
                        // Otherwise, return the seat as it is
                        return prevSeat
                    })
                } else {
                    // If the user is not found, add a new seat object
                    socket.emit('getParticipants')
                    return prevSeats
                }
            })
        })

        socket.on('participants', (participants) => {
            setSeats(prevSeats => {
                if (prevSeats.length) {
                    const participantsNotInSeats = participants.filter((participant) => {
                        return !prevSeats.some((seat) => seat.user_id === participant.user_id)
                    })

                    return [...prevSeats, ...participantsNotInSeats]
                }

                let mySeat = null
                let otherParticipants = []
                participants.forEach((participant) => {
                    if (participant.user_id != localStorage.getItem('userId')) { // eslint-disable-line eqeqeq
                        otherParticipants.push(participant)
                    } else {
                        mySeat = participant
                    }
                })

                mySeat.seat_position = myPrefSeat

                for (const participant of otherParticipants.sort((a, b) => a.seat_position - b.seat_position)) {
                    if (participant.seat_position == mySeat.seat_position) { // eslint-disable-line eqeqeq
                        mySeat.seat_position++
                    } else {
                        break
                    }
                }

                updateMySeat(mySeat.seat_position, socket)

                return [...otherParticipants, mySeat]
            })
        })

        // Call a new user and connect with them
        socket.on('addMeToCall', (peerId) => {
            peer.connect(peerId).on('open', () => {
                peer.call(peerId, mediaStream)
            })
        })

        socket.on('participantDisconnected', (peerId) => {
            setParticipants(prevParticipants => prevParticipants.filter(participant => participant.peer !== peerId))
            // Remove seat
            setSeats(prevSeats => {
                // Check if the user_id is in prevSeats
                const seatIndex = prevSeats.findIndex(prevSeat => prevSeat.peer_id === peerId)

                if (seatIndex !== -1) {
                    // If user_id is found, remove the seat
                    return prevSeats.filter((prevSeat, index) => index !== seatIndex)
                } else {
                    // If user_id is not found, return the seats as they are
                    return prevSeats
                }
            })
        })

        // Get list of participants for their seats
        socket.emit('getParticipants')

        // Now this is where we ask everyone to connect with me
        // console.log('Calling user', peerId)
        socket.emit('connectCall', peerId)
    }

    return (
        <>
            <Helmet>
                <title>bingeAsk - Find friends to watch movie together!</title>
            </Helmet>
            <div
                className="w-100 h-100 bg-dark m-0"
                style={{ maxWidth: '100vw', display: modalOpen ? 'block' : 'none' }}
            >
                <div style={{ alignItems: 'center', display: 'flex', height: '100vh' }}>
                    <Card className="bg-dark align-items-center rounded-0" style={{ width: '100vw' }}>
                        <div className="text-center">
                            <img src={require("assets/img/logo/bingeAsk-light-full.webp")} className="img-fluid h-3" alt="bingeAsk Logo" width="160" height="300" />
                            <div>
                                <h1 className="fw-600 mt-2 mb-4" style={{ fontSize: 14 }}><span className="text-light-gray">Find friends,</span> <span className="text-light-gray">Watch together</span></h1>
                            </div>
                        </div>
                        <CardBody className="text-center w-100 p-2">
                            <div style={{ height: '34%' }}>
                                <video className="rounded-0 video-mirror img-fluid video-bg" ref={videoRef}></video>
                                <div className="p-2 bg-dark d-flex align-items-center justify-content-center ai-bg" id="ai-bg">
                                    <div className="ai-line-1" style={{ height: audioFrequencies[1] }}></div>
                                    <div className="mx-1 ai-line-2" style={{ height: audioFrequencies[2] }}></div>
                                    <div className="ai-line-3" style={{ height: audioFrequencies[3] }}></div>
                                </div>
                            </div>
                            <div>
                                <span className="audio-video-icons" id="audio-video-icons">
                                    <i className={`fa-solid ${mic ? 'fa-microphone' : 'fa-microphone-slash'} text-light-gray pl-2 pr-2 cursor-pointer microphone-btn`} onClick={() => setMic(!mic)}></i>
                                    <i className={`fa-solid ${camera ? 'fa-video' : 'fa-video-slash'} text-light-gray pl-2 pr-2 cursor-pointer`} onClick={() => setCamera(!camera)}></i>
                                </span>
                                <i className="fa-solid fa-cog text-light-gray pl-2 pr-2 move-to-seat cursor-pointer" href="#collapse" id="SettingCollapse"></i>
                            </div>
                            <Button color="primary" className="font-weight-500 fs-12 mt-4 mr-0" onClick={() => startWatchParty(socket, peer, peerId)} disabled={joinBtnEnabled ? false : true}>{joinBtnEnabled ? 'Join Party' : 'Connecting...'}</Button>

                            <UncontrolledCollapse toggler="#SettingCollapse">
                                <FormGroup className="w-100 mt-4 mb-0">
                                    <label className="form-control-label text-light-gray font-weight-500 fs-12" htmlFor="exampleFormControlSelect1">Change Video Input</label>
                                    <Input id="exampleFormControlSelect1" type="select" className="bg-dark border-dark" onChange={(e) => setCameraDevice(e.target.value)}>
                                        {cameraDevices.map((cameraDevice, i) => <option key={i} value={cameraDevice.deviceId}>{cameraDevice.label}</option>)}
                                    </Input>
                                </FormGroup>

                                <FormGroup className="w-100 mt-2">
                                    <label className="form-control-label text-light-gray font-weight-500 fs-12" htmlFor="exampleFormControlSelect2">Change Audio Input</label>
                                    <Input id="exampleFormControlSelect2" type="select" className="bg-dark border-dark" onChange={(e) => setMicDevice(e.target.value)}>
                                        {micDevices.map((micDevice, i) => <option key={i} value={micDevice.deviceId}>{micDevice.label}</option>)}
                                    </Input>
                                </FormGroup>
                            </UncontrolledCollapse>
                        </CardBody>
                    </Card>
                </div>
            </div>

            <TabContent activeTab={"tabs" + navPills} style={{ display: modalOpen ? 'none' : 'block' }}>
                <TabPane tabId="tabs1">
                    <div id="ai-bg-inside" style={{ position: 'absolute', top: '29%', zIndex: 1 }}></div>
                    <UseSwiper participants={participants} />
                    <div className="text-center mt-4">
                        <img src={require("assets/img/logo/bingeAsk-light-full.webp")} className="img-fluid" alt="bingeAsk Logo" width="65" />
                    </div>
                    <p className="text-light-gray mb-3 mt-0 font-weight-500 fs-12 text-center">{ticketDetails.user_name}'s Theatre</p>
                    <UseSeatingArrangement
                        ticketToken={ticketDetails.ticket_token}
                        seats={seats}
                        updateMySeat={(seatPosition) => updateMySeat(seatPosition, socket)}
                    />
                </TabPane>
                <TabPane tabId="tabs2">
                    <section className="w-100">

                        <main className="msger-chat">
                            <div className="msg left-msg">
                                <div className="msg-img" style={{ backgroundImage: "" }}></div>
                                <div className="msg-bubble">
                                    <div className="msg-info">
                                        <div className="msg-info-name">BOT</div>
                                        <div className="msg-info-time">12:45</div>
                                    </div>

                                    <div className="msg-text">
                                        Hi, welcome to SimpleChat! Go ahead and send me a message. 😄
                                    </div>
                                </div>
                            </div>

                            <div className="msg right-msg">
                                <div className="msg-img" style={{ backgroundImage: "" }}></div>

                                <div className="msg-bubble">
                                    <div className="msg-info">
                                        <div className="msg-info-name">Sajad</div>
                                        <div className="msg-info-time">12:46</div>
                                    </div>

                                    <div className="msg-text">
                                        You can change your name in JS section!
                                    </div>
                                </div>
                            </div>
                            <div className="msg right-msg">
                                <div className="msg-img" style={{ backgroundImage: "" }}></div>

                                <div className="msg-bubble">
                                    <div className="msg-info">
                                        <div className="msg-info-name">Sajad</div>
                                        <div className="msg-info-time">12:46</div>
                                    </div>

                                    <div className="msg-text">
                                        You can change your name in JS section! 🙈🐵🐵
                                    </div>
                                </div>
                            </div>
                            <div className="msg right-msg">
                                <div className="msg-img" style={{ backgroundImage: "" }}></div>

                                <div className="msg-bubble">
                                    <div className="msg-info">
                                        <div className="msg-info-name">Sajad</div>
                                        <div className="msg-info-time">12:46</div>
                                    </div>

                                    <div className="msg-text">
                                        <span style={{ fontSize: 30 }}>🙈🐵🐵🙈😄😁😆😅😂🤣😀🤭😃😬😈😭😣</span>
                                    </div>
                                </div>
                            </div>
                        </main>
                        <div className="row align-items-center mx-0 pt-2">
                            <div className="col-auto">
                                <Button color="success" size="sm" type="button">
                                    <i className="fa fa-smile"></i>
                                </Button>
                            </div>
                            <div className="col px-0">
                                <input type="text" className="form-control bg-dark text-light-gray" placeholder="Enter your message..." style={{ height: 'calc(2em + 1.25rem + 0px)' }} />
                            </div>
                            <div className="col-auto">
                                <Button color="success" size="sm" type="button">
                                    <i className="fa fa-paper-plane"></i>
                                </Button>
                            </div>
                        </div>
                    </section>
                </TabPane>
            </TabContent>

            <div className="justify-content-center bottom-nav-bg" style={{ height: '68px', display: modalOpen ? 'none' : 'block' }}>
                <div className="justify-content-center bottom-nav" style={{ left: '1rem', right: '1rem', bottom: '12px' }}>
                    <Nav
                        className="nav-fill flex-sm-row  l-15 r-15"
                        id="tabs-text"
                        pills
                        role="tablist"
                    >
                        <NavItem className="mb-0">
                            <NavLink
                                aria-selected={navPills === 1}
                                className={classnames("mb-sm-0 mb-md-0", {
                                    active: navPills === 1
                                })}
                                onClick={() => setNavPills(1)}
                                role="tab"
                            >
                                Theatre
                            </NavLink>
                        </NavItem>
                        <NavItem className="mb-0">
                            <NavLink
                                aria-selected={navPills === 2}
                                className={classnames("mb-sm-0 mb-md-0", {
                                    active: navPills === 2
                                })}
                                // onClick={() => setNavPills(2)}
                                onClick={() => reconnectCall()}
                                role="tab"
                            >
                                Messages
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
            </div>
        </>
    )
}

export default Theatre
