import { Helmet } from 'react-helmet'
import UseFooter from "components/Footers/Footer"
import UseHeader from "components/Headers/Header"
import UseBottomNav from "components/Navigation/BottomNav"
import UseSignUpCard from "components/Cards/SignUp"
import { useEffect, useState } from "react"
import { AddShowModal } from "components/Modals/AddShowModal"

import {
	Card,
	CardImg,
	CardBody,
	Badge
} from "reactstrap"
import axios from "axios"
import { useParams } from 'react-router-dom'
import showAlert from '../components/Alert'

const Profile = () => {

	const [signedIn, setSignedIn] = useState(true)
	const [addShowModalOpen, setAddShowModalOpen] = useState(false)
	const [watchedShows, setWatchedShows] = useState([])
	const [user, setUser] = useState({
		user_name: '',
		user_pfp: ''
	})
	const [genres, setGenre] = useState([])
	const [pageLoading, setPageLoading] = useState(true)
	const [imageLoading, setImageLoading] = useState(true)

	const { id } = useParams()

	useEffect(() => {
		const userEmail = localStorage.getItem('userEmail')
		if (!userEmail) {
			setSignedIn(false)
		} else {
			getWatchedShows()
		}

		if (id !== undefined) {
			// Get the user
			axios.get(`${process.env.REACT_APP_BACKEND_URL}user/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem('jwt')}`
				}
			}).then((resp) => {
				setUser({
					user_name: resp.data.user.user_name,
					user_pfp: resp.data.user.user_pfp
				})
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	const signedUp = () => {
		setSignedIn(true)
		getWatchedShows()
	}

	const getWatchedShows = () => {
		axios.get(`${process.env.REACT_APP_BACKEND_URL}user/${id !== undefined ? id : 'me'}/show`, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('jwt')}`
			}
		}).then((resp) => {
			setWatchedShows(resp.data.shows)
			let genres = []
			resp.data.shows.forEach((show) => {
				if (show.show_genres) {
					genres.push(...JSON.parse(show.show_genres))
				}
			})
			let counts = {}
			genres.forEach(element => {
				counts[element] = (counts[element] || 0) + 1
			})
			const sorted = Object.keys(counts).sort((a, b) => counts[b] - counts[a])
			const uniqueSorted = sorted.filter((value, index, self) => self.indexOf(value) === index)
			setGenre(uniqueSorted.slice(0, 5))
			setPageLoading(false)
		})
	}

	const toggleFav = (show_id, fav) => {
		const favShowsCount = watchedShows.filter(watchedShow => watchedShow.fav === 1).length

		if (favShowsCount > 4 && fav === 0) {
			showAlert('danger', 'Maximum 5 favorite shows can be added. Remove a favorite to add this show.')
			return
		}

		setWatchedShows(oldWatchedShows => {
			const updatedWatchedShows = oldWatchedShows.map(watchedShow => {
				if (watchedShow.show_id === show_id) {
					// eslint-disable-next-line
					return { ...watchedShow, fav: fav == 1 ? 0 : 1 }
				}
				return watchedShow
			})
			return updatedWatchedShows
		})
		if (fav) {
			showAlert('primary', 'Favorite removed from the list.')
		} else {
			showAlert('success', 'Favorite added to the list.')
		}
		axios.put(`${process.env.REACT_APP_BACKEND_URL}user/me/show/${show_id}`, {
			// eslint-disable-next-line
			fav: fav == 1 ? 0 : 1
		}, {
			headers: {
				Authorization: `Bearer ${localStorage.getItem('jwt')}`
			}
		})
	}

	const signout = () => {
		localStorage.clear()
		localStorage.setItem('alreadyUser', 'true')
		window.location.reload()
	}

	return (
		<>
			<Helmet>
				<title>bingeAsk - Find friends to watch movie together!</title>
			</Helmet>
			<div className="mt-4">
				<div className="text-center">
					<UseHeader />
				</div>

				{signedIn ? (
					<div className="container mt-4">
						<div style={{ zIndex: 1, minHeight: '80vh' }}>
							<div className="row">
								<div className="col-md-3">
									<div className="card bg-dark gradient-card border-0 mb-4" style={{ position: 'sticky', top: 40 }}>
										<div className="card-body pt-4 pb-3">
											{ imageLoading ? (
												<div className="skeleton-loader">
													<div style={{ height: 80, width: 80, borderRadius: 100 }} className="skeleton-block"></div>
												</div>
											) : null }
											<CardImg
												alt={`${id === undefined ? localStorage.getItem('userName') : user.user_name} on bingeAsk`}
												src={`${process.env.REACT_APP_PFP_URL}${id === undefined ? localStorage.getItem('pfp') : user.user_pfp}`}
												className="img-fluid gradient-card"
												height="90"
												width="90"
												style={{ height: 80, width: 80, borderRadius: 100, objectFit: 'cover', display: imageLoading ? 'none': 'block' }}
												rel="preload"
												type="image/webp"
												onLoad={() => setImageLoading(false)}
											/>
											<h4 className="text-light-gray font-weight-600 mt-2">{id === undefined ? localStorage.getItem('userName') : user.user_name}</h4>
											{genres.map((genre, i) => (
												<Badge className="mr-1 mb-1 p-2 bg-dark text-light text-capitalize font-weight-400" key={i}>{genre}</Badge>
											))}
										</div>

										{id !== undefined ? (
											<div className="card-footer bg-s py-3 text-center">
												<p className="font-weight-600 mb-0 text-dark" type="button" style={{ fontSize: 13 }}>
													Message
												</p>
											</div>
										) : (
											<>
												<div className="card-footer py-0 bg-light-dark text-center">
													<button className="btn bg-s w-100 font-weight-600 mb-0 text-dark" type="button" onClick={() => setAddShowModalOpen(!addShowModalOpen)} style={{ fontSize: 13 }}>
														Add Movies
													</button>
												</div>
												<p className="text-gray fs-12 font-weight-500 mt-3 text-center cursor-pointer" onClick={() => signout()} >
													Sign out
												</p>
											</>
										)}

									</div>
								</div>
								<div className="col-md-9">
									{watchedShows.filter(show => {
										// eslint-disable-next-line
										return show.fav == 1
									}).length ? (
										<>
											<h5 className="text-light-gray font-weight-600 ml-1">Top 5 favorite & recommended</h5>
											<div className="row px-2 mt-3">
												{watchedShows
													.filter(show => {
														// eslint-disable-next-line
														return show.fav == 1
													})
													.map((show, i) => (
														<div className="col-md-2 col-4 px-2 mb-3" key={i}>
															<Card className="bg-dark">
																<CardImg
																	alt="Watch Together - bingeAsk"
																	src={"https://image.tmdb.org/t/p/original/" + show.show_poster}
																	className="img-fluid"
																	width="600" height="400"
																	rel="preload"
																	type="image/webp"
																/>
																<CardBody className="bg-dark p-3">
																	<h5 className="text-light-gray mb-0 text-ellipsis font-weight-400 fs-12">{show.show_title}</h5>
																	<small className="text-gray fs-12 text-ellipsis">IMDb {show.show_rating.toFixed(2)}</small>
																</CardBody>
															</Card>
														</div>
													))}
											</div>
										</>
									) : null }
									{pageLoading ? (
										<>
											<h5 className="text-light-gray font-weight-600 ml-1">Top 5 favorite & recommended</h5>
											<div className="row mt-3">
												{ Array.from({ length: 6 }, (_, index) => (
													<div className="col-2" key={index}>
														<div className="skeleton-loader">
															<div style={{ height: 227, width: '100%'}} className="skeleton-block-dark"></div>
														</div>
													</div>
												)) }
											</div>
										</>
									) : null}

									{watchedShows.length  ? (
										<>
											<h5 className="text-light-gray font-weight-600 mt-4 ml-1">All watched shows</h5>
											<div className="row px-2 mt-3">
												{watchedShows.map((show, i) => (
													<div className="col-sm-12 col-md-3 px-2 mb-4" key={i}>
														<Card className="bg-dark">
															<CardImg
																alt="Watch Together - bingeAsk"
																src={"https://image.tmdb.org/t/p/original/" + show.show_backdrop}
																className="img-fluid h-100"
																width="600" height="400"
																rel="preload"
																type="image/webp"
															/>
															<CardBody className="bg-dark p-3">
																<div className="row align-items-center">
																	<div className="col">
																		<h5 className="text-light-gray mb-0 text-ellipsis font-weight-400 fs-12">{show.show_title}</h5>
																		<small className="text-gray fs-12">IMDb {show.show_rating.toFixed(2)}/10</small>
																	</div>
																	{id === undefined ? (
																		<div className="col-auto cursor-pointer" onClick={() => toggleFav(show.show_id, show.fav)}>
																			<i className={"fa fa-star text-s " + (show.fav ? '' : 'text-gray small')}></i>
																		</div>
																	) : null}
																</div>
															</CardBody>
														</Card>
													</div>
												))}
											</div>
										</>
									) : null }
									{pageLoading ? (
										<>
											<h5 className="text-light-gray font-weight-600 ml-1 mt-4">All watched shows</h5>
											<div className="row mt-3">
												{ Array.from({ length: 9 }, (_, index) => (
													<div className="col-12 col-md-3 mb-4" key={index}>
														<div className="skeleton-loader">
															<div style={{ height: 140, width: '100%' }} className="skeleton-block-dark"></div>
														</div>
													</div>
												)) }
											</div>
										</>
									) : null }
								</div>
							</div>
						</div>
						<AddShowModal setModalOpen={(status) => { setAddShowModalOpen(status); getWatchedShows() }} modalOpen={addShowModalOpen} />
					</div>
				) : (
					<UseSignUpCard completionEvent={signedUp} />
				)}
				<UseBottomNav page="profile" />
				<div style={{ marginBottom: '14vh', marginTop: 40 }}>
					<UseFooter />
				</div>
			</div>
		</>
	)
}

export default Profile
