import { Helmet } from 'react-helmet'
import UseFooter from "components/Footers/Footer"
import UseHeader from "components/Headers/Header"
import UseBottomNav from "components/Navigation/BottomNav"
import {
	Card,
	CardImg,
    CardBody
} from "reactstrap";

const BookSeat = (props) => {

    const { seats } = props
	return (
		<>
			<Helmet>
				<title>Find Friends | bingeAsk</title>
			</Helmet>
			<div className="mt-4">
				<div className="text-center">
					<UseHeader />
				</div>
				<div className="container" style={{ height: '80vh' }}>
					<div className="row justify-content-center py-4 mt-4" >
						<div className="col-12 col-md-6">
                            <Card className="bg-transparent mt-4 pt-2">
                                <CardImg
                                    alt="Watch Together - bingeAsk"
                                    src={require("../assets/img/brand/bingeAsk-Movie-Night-Banner.jpg")}
                                    className="img-fluid h-100"
                                    width="600" height="400"
                                    rel="preload"
									type="image/webp"
                                />
                                <CardBody className="p-0 mt-4">
                                    <small className="text-gray">Description</small>
                                    <h5 className="text-light-gray mt-3 mb-3">
                                    Event: Saturday Movie Night on bingeAsk – "Yeh Jawani Hai Diwani"
                                    </h5>
                                    <div className="small text-light-gray mt-2">
                                        📅 Date: 21st September<br></br>
                                        ⏰ Time: 11:00 PM<br></br>
                                        📺 Platform: Netflix (Bring your own Netflix account)
                                        <br></br><br></br>
                                        Join us for an exciting Saturday Movie Night on bingeAsk! This week, we're bringing you the Bollywood classic "Yeh Jawani Hai Diwani", a vibrant film full of romance, adventure, and nostalgia. Whether you're revisiting this fan-favorite or watching it for the first time, you’ll have a great time experiencing it with friends you meet on our platform.
                                    </div>
                                    <h4 className="text-a mt-4">Why bingeAsk?</h4>
                                    <div className="small text-light-gray mt-2">
                                        bingeAsk isn’t just about watching movies; it’s about making connections. We bring people together who share the same taste in movies, giving you the chance to — <br></br>~ Chat, <br></br>~ Laugh, <br></br> ~ Enjoy your favorite films together, no matter where you are. The concept is as cool as it sounds—imagine the thrill of watching alongside fellow movie lovers in real-time!
                                    </div>
                                    
                                    <h4 className="text-s mt-4">What You Need:</h4>
                                    <div className="small text-light-gray mt-2">
                                        Each participant will need their own Netflix account to join the viewing. We’ve got the virtual living room ready—all you need is <br></br>~ Your Popcorn, <br></br>~ A Comfy Spot, <br></br>And the energy to stay up for this awesome movie night!
                                    </div>

                                    <h4 className="text-k mt-4">How to Join:</h4>
                                    <div className="small text-light-gray mt-2">
                                        Sign up on bingeAsk, make new friends, and immerse yourself in a movie experience like no other. Whether it's your first Saturday Movie Night or you're a regular, bingeAsk is where fun meets connection.
                                    </div>

                                    <div className="small text-light-gray mt-4 mb-7">
                                        Get ready for a night of entertainment and camaraderie! 🎥✨
                                    </div>
                                </CardBody>
                            </Card>
						</div>
                        <div className="col-12 col-md-4 text-center">
                            <Card className="bg-dark" style={{ position: 'sticky', top: 40 }}>
                                <CardBody className="">
                                    <small className="text-s" style={{fontSize: '.7rem'}}>21 Seats Left</small>
                                    <h5 className="text-light-gray font-weight-500 mb-3">Select Your Seat</h5>
                                    <div className="justify-content-center" style={{ display: 'grid' }}>
                                        <div style={{ width: '300px' }}>
                                            {/* First Row with 4 seats */}
                                            <div className="row justify-content-center">
                                                {[...Array(4)].map((_, index) => {
                                                    return (
                                                        <span key={index} className="theatre-seat">
                                                                
                                                        </span>
                                                    );
                                                })}
                                            </div>

                                            {/* Second Row with 6 seats */}
                                            <div className="row justify-content-center">
                                                {[...Array(6)].map((_, index) => {
                                                    return (
                                                        <span key={index} className="theatre-seat">
                                                                
                                                        </span>
                                                    );
                                                })}
                                            </div>

                                            {/* Next Four Rows with 9 seats each */}
                                            {[10, 19, 28, 37].map((start, rowIndex) => (
                                                <div key={rowIndex} className="row justify-content-center">
                                                    {[...Array(9)].map((_, index) => {
                                                        return (
                                                            <span key={index} className="theatre-seat">
                                                                    
                                                            </span>
                                                        );
                                                    })}
                                                </div>
                                            ))}

                                            {/* Seventh Row with 6 seats */}
                                            <div className="row justify-content-center">
                                                {[...Array(6)].map((_, index) => {
                                                    return (
                                                        <span key={index} className="theatre-seat">
                                                                
                                                        </span>
                                                    );
                                                })}
                                            </div>

                                            {/* Eighth Row with 4 seats */}
                                            <div className="row justify-content-center">
                                                {[...Array(4)].map((_, index) => {
                                                    return (
                                                        <span key={index} className="theatre-seat">
                                                                
                                                        </span>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        
                                    </div>
                                    <div className="row align-item-center mt-4">
                                        <div className="col text-left">
                                            <h5 className="text-light-gray mb-0">2 Seat × ₹50 = ₹100</h5>
                                            <small className="text-gray">Virtual Night Out Offer 100% Off</small>
                                        </div>
                                        <div className="col-auto text-right">
                                            <button className="btn bg-s w-100 font-weight-600 mb-0 text-dark" type="button" style={{ fontSize: 13 }}>
                                                Book
                                            </button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
						</div>
					</div>
				</div>
				<UseBottomNav page="seat" />
			</div>
		</>
	)
}

export default BookSeat
